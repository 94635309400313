/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

import emoji from "react-easy-emoji";
import splashAnimation from "./assets/lottie/splashAnimation"; // Rename to your file name for custom animation

// Splash Screen

const splashScreen = {
  enabled: true, // set false to disable splash screen
  animation: splashAnimation,
  duration: 1000 // Set animation duration as per your animation
};

// Summary And Greeting Section

const illustration = {
  animated: true // Set to false to use static SVG
};

const greeting = {
  username: "Henrique Goncalves",
  title: "Hello, I'm Henrique",
  subTitle: emoji(
    "A dedicated Information Security Professional with over a decade of experience in building secure, scalable software systems using Python, Golang, Clojure, and several other powerful languages and frameworks. I have a strong track record in leading cross-functional teams, driving innovative security initiatives, and architecting resilient multi-cloud architectures."
  ),
  resumeLink: "https://resume.hadenes.io",
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/kamushadenes",
  linkedin: "https://www.linkedin.com/in/kamushadenes/",
  gmail: "kamus@hadenes.io",
  medium: "https://medium.com/@kamushadenes",
  // Instagram, Twitter and Kaggle are also supported in the links!
  // To customize icons and social links, tweak src/components/SocialMedia
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "Skills",
  subTitle:
    "Everything listed here is something I have actually deployed and maintained in production",
  skills: [
    emoji("⚡ Scalable, secure, and resilient cloud architectures"),
    emoji("⚡ Data pipelines and ETLs"),
    emoji("⚡ Complex, highly-scalable systems"),
    emoji("⚡ Highly regulated environments, such as PCI-DSS, SOX, and GDPR")
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "Golang",
      fontAwesomeClassname: "fa-brands fa-golang"
    },
    {
      skillName: "Python",
      fontAwesomeClassname: "fab fa-python"
    },
    {
      skillName: "Clojure",
      fontAwesomeClassname: "devicon-clojure-plain"
    },
    {
      skillName: "Rust",
      fontAwesomeClassname: "fab fa-rust"
    },
    {
      skillName: "NodeJS",
      fontAwesomeClassname: "fab fa-node-js"
    },
    {
      skillName: "C",
      fontAwesomeClassname: "devicon-c-plain"
    },
    {
      skillName: "Bash",
      fontAwesomeClassname: "devicon-bash-plain"
    },
    {
      skillName: "AWS",
      fontAwesomeClassname: "fab fa-aws"
    },
    {
      skillName: "GCP",
      fontAwesomeClassname: "devicon-googlecloud-plain"
    },
    {
      skillName: "Azure",
      fontAwesomeClassname: "devicon-azure-plain"
    },
    {
      skillName: "Firebase",
      fontAwesomeClassname: "devicon-firebase-plain"
    },
    {
      skillName: "PostgreSQL",
      fontAwesomeClassname: "devicon-postgresql-plain"
    },
    {
      skillName: "MySQL",
      fontAwesomeClassname: "devicon-mysql-plain"
    },
    {
      skillName: "Redis",
      fontAwesomeClassname: "devicon-redis-plain"
    },
    {
      skillName: "Airflow",
      fontAwesomeClassname: "devicon-apacheairflow-plain"
    },
    {
      skillName: "Grafana",
      fontAwesomeClassname: "devicon-grafana-plain"
    },
    {
      skillName: "Prometheus",
      fontAwesomeClassname: "devicon-prometheus-original"
    },
    {
      skillName: "Docker",
      fontAwesomeClassname: "fab fa-docker"
    },
    {
      skillName: "Kubernetes",
      fontAwesomeClassname: "devicon-kubernetes-plain"
    },
    {
      skillName: "Helm",
      fontAwesomeClassname: "devicon-helm-plain"
    },
    {
      skillName: "ArgoCD",
      fontAwesomeClassname: "devicon-argocd-plain"
    },
    {
      skillName: "Vagrant",
      fontAwesomeClassname: "devicon-vagrant-plain"
    },
    {
      skillName: "QEMU",
      fontAwesomeClassname: "fa-solid fa-computer"
    },
    {
      skillName: "Terraform",
      fontAwesomeClassname: "devicon-terraform-plain"
    },
    {
      skillName: "Pulumi",
      fontAwesomeClassname: "fa-solid fa-code"
    },
    {
      skillName: "Ansible",
      fontAwesomeClassname: "devicon-ansible-plain"
    },
    {
      skillName: "Selenium",
      fontAwesomeClassname: "devicon-selenium-plain"
    },
    {
      skillName: "Arduino",
      fontAwesomeClassname: "devicon-arduino-plain"
    },
    {
      skillName: "Jenkins",
      fontAwesomeClassname: "devicon-jenkins-plain"
    },
    {
      skillName: "GitHub Actions",
      fontAwesomeClassname: "devicon-githubactions-plain"
    },
    {
      skillName: "GraphQL",
      fontAwesomeClassname: "devicon-graphql-plain"
    },
    {
      skillName: "Django",
      fontAwesomeClassname: "devicon-django-plain"
    },
    {
      skillName: "Flask",
      fontAwesomeClassname: "devicon-flask-plain"
    },
    {
      skillName: "Hugo",
      fontAwesomeClassname: "devicon-hugo-plain"
    },
    {
      skillName: "Apache",
      fontAwesomeClassname: "devicon-apache-plain"
    },
    {
      skillName: "NGINX",
      fontAwesomeClassname: "devicon-nginx-plain"
    },
    {
      skillName: "Linux",
      fontAwesomeClassname: "devicon-linux-plain"
    },
    {
      skillName: "MacOS",
      fontAwesomeClassname: "devicon-apple-original"
    },
    {
      skillName: "Windows",
      fontAwesomeClassname: "fab fa-windows"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: false, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "Harvard University",
      logo: require("./assets/images/harvardLogo.png"),
      subHeader: "Master of Science in Computer Science",
      duration: "September 2017 - April 2019",
      desc: "Participated in the research of XXX and published 3 papers.",
      descBullets: [
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit"
      ]
    },
    {
      schoolName: "Stanford University",
      logo: require("./assets/images/stanfordLogo.png"),
      subHeader: "Bachelor of Science in Computer Science",
      duration: "September 2013 - April 2017",
      desc: "Ranked top 10% in the program. Took courses about Software Engineering, Web Security, Operating Systems, ...",
      descBullets: ["Lorem ipsum dolor sit amet, consectetur adipiscing elit"]
    }
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: false, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Cybersecurity", //Insert stack or technology you have experience in
      progressPercentage: "99%" //Insert relative proficiency in percentage
    },
    {
      Stack: "Software Engineering",
      progressPercentage: "90%"
    },
    {
      Stack: "Artifical Intelligence",
      progressPercentage: "70%"
    }
  ],
  displayCodersrank: true // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Global Head of Defensive Security",
      company: "Nubank",
      companylogo: require("./assets/images/nubankLogo.png"),
      date: "December 2019 – January 2023",
      desc:
        "As the Global Head of the Infosec Defensive Alliance, I was responsible for leading teams " +
        "focused on various areas of security, including application security, cloud security, " +
        "network security, data security, and enterprise infrastructure security.",
      descBullets: [
        "Led over 30 world-class professionals across multiple global squads to ensure the security of the entire software development process, from the developer's machine all the way to the cloud and cluster the workload runs on",
        "Delivered critical parts of SOX Compliance pre-IPO",
        "Created custom, open source security tooling for niche language not covered by the market, namely SAST and SCA for Clojure",
        "Mentored other leaders in topics such as leadership, communication and storytelling",
        "My leadership effectiveness and the team's overall sentiment about my management style were clearly reflected in the half-year engagement survey of 2022. I was honored to\n" +
          "receive an impressive managerial rating of 99 out of 100, illustrating the high levels of trust and respect from my team",
        "Furthermore, we accomplished a remarkable 97 out of 100 in the eSat rating, which underlines the exceptional satisfaction and engagement within the company under my stewardship"
      ]
    },
    {
      role: "Chief Security Officer",
      company: "Quanto",
      companylogo: require("./assets/images/quantoLogo.png"),
      date: "July 2017 - December 2019",
      desc:
        "As one of the first four employees of Quanto and its Chief Security Officer, I played a key role " +
        "in building the foundation of the company and driving its overall strategy.",
      descBullets: [
        "Led the company to a successful $15 million Series A round, attracting the participation of Brazil's two largest banks",
        "Implemented an architectural model that ensured the complete confidentiality of encrypted customer data, resulting in a zero-knowledge security model",
        "Designed and built a multi-cloud, no-single-point-of-failure architecture that was resilient to catastrophic events and the disappearance of cloud providers",
        "Coordinated multiple teams on multiple projects, serving as both a technical leader and a people leader"
      ]
    }
  ]
};

const workExperiencesExtra = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Cloud Platform Engineer",
      company: "Altinity, Inc.",
      companylogo: require("./assets/images/altinityLogo.png"),
      date: "February 2024 – Present",
      desc: "Responsible for developing and maintaining the cloud infrastructure of Altinity, Inc., a company that provides support and services for ClickHouse.",
      descBullets: [
        "Engineered CHGuard, a tool to safeguard ClickHouse servers against DDoS attacks, brute force attempts, and unreliable clients using the reverse engineered native binary protocol of ClickHouse.",
        "Developed synchronization tools for Docker registries, ensuring reliability and consistency across environments.",
        "Created Prometheus-based tools to proactively monitor and manage cloud resource quotas, preventing limit breaches.",
        "Designed and implemented a Write-Ahead Logging (WAL) mechanism to ensure data integrity and a comprehensive audit trail for system activities."
      ]
    },
    {
      role: "Head of Security and Privacy",
      company: "Wildlife Studios",
      companylogo: require("./assets/images/wildlifeLogo.png"),
      date: "April 2023 – January 2024",
      desc: "Responsible for leading the Security and Privacy team at Wildlife Studios, a mobile gaming company with over 2 billion downloads and 1,000 employees.",
      descBullets: [
        "Developed a DLP solution to prevent Google Drive data exfiltration",
        "Rolled out FleetDM, a device management solution, to over 800 employees",
        "Developed a solution to perform SPF Flattening and to maintain up-to-date SPF records",
        "Deployed Wazuh, an open source SIEM, to monitor endpoints, OSINT and cloud infrastructure"
      ]
    },
    {
      role: "Senior Cloud Architect",
      company: "Zag",
      companylogo: require("./assets/images/zagLogo.png"),
      date: "May 2018 - February 2019",
      desc: "As a Senior Cloud Architect at Zag, I was responsible for implementing innovative solutions to increase the security of managed customer infrastructure and increase the productivity of the SAP team.",
      descBullets: [
        "Worked with cloud automation in order to increase the security of managed customer infrastructure",
        "Worked with the engineering team to implement security auditing on AWS across multiple customer's accounts",
        "Increased the productivity of the SAP team by developing automated installation scripts in Go which worked together with AWS SSM, reducing the mean deploy time from a week to a few hours"
      ]
    },
    {
      role: "Head of Security",
      company: "Pagar.me",
      companylogo: require("./assets/images/pagarmeLogo.png"),
      date: "March 2017 - April 2018",
      desc: "As Head of Information Security at Pagar.me, I was responsible for overseeing the company's first PCI-DSS certification process, establishing and building the Information Security area from the ground up, and ensuring the smooth operation of the company during high-traffic events.",
      descBullets: [
        "Coordinated the first PCI-DSS certification process from conception to deliver",
        "Built the whole Information Security area from the ground up",
        "Acted as Tech Leader, making architectural decisions about the entire technology stack"
      ]
    }
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: true // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "Big Projects",
  subtitle: "Startups and companies that I have helped to build.",
  projects: [
    {
      image: require("./assets/images/seLogo.png"),
      projectName: "Stellar Entropy",
      projectDesc: "Open Finance Hard Tech",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://se.team"
        }
      ]
    },
    {
      image: require("./assets/images/papiroLogo.png"),
      projectName: "Papiro",
      projectDesc:
        "Papiro reads your emails and, based on your personalized rules, takes actions that save you time and eliminate hassle.",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://papiro.cloud"
        }
        //  you can add extra buttons here.
      ]
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Achievements And Certifications 🏆 "),
  subtitle: "",
  //subtitle: "Achievements, Certifications, Award Letters and Some Cool Stuff that I have done !",

  achievementsCards: [
    {
      title: "Security Engineer",
      subtitle: "",
      image: require("./assets/images/awsLogo.jpg"),
      imageAlt: "Amazon Web Services",
      footerLink: []
      /*
      footerLink: [
        {
          name: "Certification",
          url: "https://drive.google.com/file/d/0B7kazrtMwm5dYkVvNjdNWjNybWJrbndFSHpNY2NFV1p4YmU0/view?usp=sharing"
        },
        {
          name: "Award Letter",
          url: "https://drive.google.com/file/d/0B7kazrtMwm5dekxBTW5hQkg2WXUyR3QzQmR0VERiLXlGRVdF/view?usp=sharing"
        },
        {
          name: "Google Code-in Blog",
          url: "https://opensource.googleblog.com/2019/01/google-code-in-2018-winners.html"
        }
      ]
      */
    },
    {
      title: "Nexpose Certified Administrator",
      subtitle: "",
      image: require("./assets/images/rapid7Logo.jpeg"),
      imageAlt: "Rapid7",
      footerLink: []
    },
    {
      title: "ArcSigh ESM Security Analyst",
      subtitle: "",
      image: require("./assets/images/hpeLogo.jpeg"),
      imageAlt: "Rapid7",
      footerLink: []
    },
    {
      title: "LPIC-3 - Senior Level Linux Professional",
      subtitle: "",
      image: require("./assets/images/lpiLogo.jpeg"),
      imageAlt: "LPI",
      footerLink: []
    },
    {
      title: "DCTS - Novell Data Center Technical Specialist",
      subtitle: "",
      image: require("./assets/images/novellLogo.jpeg"),
      imageAlt: "Novell",
      footerLink: []
    },
    {
      title: "NCLA - Novell Certified Linux Administrator",
      subtitle: "",
      image: require("./assets/images/novellLogo.jpeg"),
      imageAlt: "Novell",
      footerLink: []
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  title: "Blogs",
  subtitle: "",
  // subtitle: "Writing is a hobby. I love to write about various topics ranging from technology to lifestyle.",
  displayMediumBlogs: "false", // Set true to display fetched medium blogs instead of hardcoded ones
  blogs: [
    {
      url: "https://blog.hadenes.io/post/making-the-most-out-of-your-yubikey-4/",
      title: "Making the Most Out of Your Yubikey 4",
      description: "How to use your Yubikey 4 to its full potential"
    },
    {
      url: "https://blog.hadenes.io/post/how-to-request-admin-permissions-in-windows-through-uac-with-golang/",
      title: "How to Request Admin Permissions in Windows via UAC in Golang",
      description:
        "In Windows, User Account Control (UAC) is a security feature that helps prevent unauthorized changes to the operating system"
    },
    {
      url: "https://blog.hadenes.io/post/convert-a-proxmox-windows-guest-from-bios-to-uefi/",
      title: "How to Convert a Proxmox Windows Guest from BIOS to UEFI",
      description:
        "In order to upgrade to a guest to Windows 11 or to passthrough a GPU, you need UEFI"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  title: "TALKS",
  subtitle: emoji(
    "I LOVE TO SHARE MY LIMITED KNOWLEDGE AND GET A SPEAKER BADGE 😅"
  ),

  talks: [
    {
      title: "Build Actions For Google Assistant",
      subtitle: "Codelab at GDG DevFest Karachi 2019",
      slides_url: "https://bit.ly/saadpasta-slides",
      event_url: "https://www.facebook.com/events/2339906106275053/"
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "I LOVE TO TALK ABOUT MYSELF AND TECHNOLOGY",

  // Please Provide with Your Podcast embeded Link
  podcast: [
    "https://anchor.fm/codevcast/embed/episodes/DevStory---Saad-Pasta-from-Karachi--Pakistan-e9givv/a-a15itvo"
  ],
  display: false // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("Contact Me ☎️"),
  number: "+55 11 99807-7063",
  email_address: "kamus@hadenes.io"
};

// Twitter Section

const twitterDetails = {
  userName: "twitter", //Replace "twitter" with your twitter username without @
  display: false // Set true to display this section, defaults to false
};

const isHireable = true; // Set false if you are not looking for a job. Also isHireable will be display as Open for opportunities: Yes/No in the GitHub footer

export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreen,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  workExperiencesExtra,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails,
  isHireable
};
